import block from 'bem-cn';
import clsx from 'clsx';
import { t } from '@lib/i18n';

import Image from '@ui/Image';
import Typography from '@ui/Typography';
import MediaQuery from '@ui/MediaQuery';

import './style.scss';

const cn = block('contact-banner');

const CONTACTS = [
  {
    name: 'LinkedIn',
    link: t('contacts.sales_man.linkedin'),
  },
  {
    name: 'cmason@tagpoint.co.uk',
    link: 'mailto:cmason@tagpoint.co.uk',
  },
];

const ContactBanner = ({ primaryText, secondaryText }) => {
  const prTextProcessed = primaryText.replace('\\n', '<br>');
  const secTextProcessed = secondaryText.replace('\\n', '<br>');

  return (
    <div className={cn('wrap').mix(clsx('border-2 border-solid border-tertiary300'))}>
      <div className="flex flex-col px-[74px] py-[66px] sm:px-[42px] sm:py-[50px] md:px-[66px] md:py-[58px]">
        <div className="flex items-center">
          <Image src={t('contacts.sales_man.avatar')} alt={t('contacts.sales_man.alt_image')} />
          <div className="ml-[18px]">
            <Typography className="!mb-0">
              {t('contacts.sales_man.name')}
            </Typography>
            <Typography className="!mt-0.5 !mb-0 sm:hidden">
              {secTextProcessed}
            </Typography>
          </div>
        </div>
        <Typography className="!mt-2 !mb-0 hidden !text-xs sm:block">
          {secTextProcessed}
        </Typography>
        <Typography className="!mt-2 !mb-0 !text-2lg">
          {prTextProcessed}
        </Typography>
        <MediaQuery greaterThan="sm">
          <div className="z-10 mt-2 flex space-x-[21px]">
            {CONTACTS.map((contact) => (
              <a href={contact.link} target="_blank" className={cn('link')}>
                {contact.name}
              </a>
            ))}
          </div>
        </MediaQuery>
        <MediaQuery at="sm">
          <div className="z-10 mt-2 flex flex-col space-y-[12px]">
            <div className="flex space-x-[21px]">
              {CONTACTS.slice(0, 1).map((contact) => (
                <a href={contact.link} target="_blank" className={cn('link')}>
                  {contact.name}
                </a>
              ))}
            </div>
            <a href={CONTACTS[1].link} target="_blank" className={cn('link')}>
              {CONTACTS[1].name}
            </a>
          </div>
        </MediaQuery>
      </div>
    </div>
  );
};

ContactBanner.defaultProps = {
  primaryText: 'Hi, my name is Chris. I will be happy to help.\\nUsually I reply by the end of business day.',
  secondaryText: 'Director of Customer Success at TagPoint',
};

export default ContactBanner;
