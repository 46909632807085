import block from 'bem-cn';

import './style.scss';
import clsx from 'clsx';

const cn = block('underlineWords');

const UnderlineWords = ({ words, className }) => (
  <span className={clsx('highlight', className)}>
    {words}
  </span>
);

export default UnderlineWords;
