import Typography from '../Typography';

const WhatsAppAnnotation = () => {
  return (
    <Typography variant="caption1" color="deepBlue5" className="mt-6">
      *WhatsApp принадлежит компании Meta, признанной экстремистской организацией и запрещённой в Российской Федерации.
    </Typography>
  );
};

export default WhatsAppAnnotation;
