import { Link } from 'gatsby';

const ImgExtender = (
  {
    href, 
    target, 
    src, 
    alt,
    style, 
    ...rest 
  },
) => {
  if (!href) {
    return (
      <img src={src} alt={alt} {...rest} STYLE={style} />
    );
  }
  const isInternalLink = /^\/(?!\/)/.test(href);
  if (isInternalLink) {
    return (
      <Link to={href} target={target === '_blank' ? '_blank' : '_self'}>
        <img src={src} alt={alt} {...rest} style={style} />
      </Link>
    );
  }
  return (
    <a href={href} target={target === '_blank' ? '_blank' : '_self'}>
      <img src={src} alt={alt} {...rest} style={style} />
    </a>
  );
};

export default ImgExtender;
