import PropTypes from 'prop-types';
import AlignContainer from '@ui/AlignContainer';
import Button, { buttonProps } from '../Button/Button';
import { useModal } from '../Modal';

const ModalButton = ({
  modalName = 'request-demo',
  text = 'Book a demo',
  onClick,
  formtext,
  align,
  ...rest
}) => {
  const { openModal } = useModal(modalName || 'request-demo', { titleOverride: formtext || text });
  const handleClick = () => {
    openModal();
    if (onClick) {
      onClick();
    }
  };
  return (
    <AlignContainer align={align}>
      <Button onClick={handleClick} text={text} {...rest} />
    </AlignContainer>
  );
};

ModalButton.propTypes = {
  ...buttonProps,
  modalName: PropTypes.string,
};

export default ModalButton;
