import { useMemo, createElement, memo } from 'react';
import {
  CaseCard, AccentText, ContactBanner, ProductBanner, Button, ImgExtender,
} from '@ui/mdEditor/components';
import StaticIcon from '@ui/StaticIcon';
import ModalButton from '@ui/Buttons/ModalButton';
import RehypeReact from 'rehype-react';

import './postContentStyle.scss';
import { WhatsAppAnnotation } from '@ui/WhatsAppAnnotation';
import ImagesRow from '../ImagesRow';

// --------------------------------------------------------------

const components = {
  modalbutton: ({ ...props }) => <ModalButton reversed {...props} />,
  casecard: ({ ...props }) => <CaseCard {...props} />,
  casecardclient: ({ ...props }) => <CaseCard type="client" {...props} />,
  productbanner: ({ ...props }) => <ProductBanner {...props} />,
  accenttext: ({ ...props }) => <AccentText {...props} />,
  contactbanner: ({ ...props }) => <ContactBanner {...props} />,
  arrow: ({ ...props }) => <StaticIcon name="post-arrow" {...props} />,
  whatsappannotation: ({ ...props }) => <WhatsAppAnnotation {...props} />,
  mobileslider: ({ ...props }) => <ImagesRow {...props} />,
  button: ({ ...props }) => <Button {...props} />,
  img: ({ ...props }) => <ImgExtender loading="lazy" alt="" {...props} />,
};

const renderer = new RehypeReact({
  createElement,
  components,
}).Compiler;

const MarkDownViewer = ({ children }) => {
  const rendered = useMemo(() => renderer(children), [children]);
  return (
    <div className="md-viewer__post_content">
      {rendered}
    </div>
  );
};

export default memo(MarkDownViewer);
