import { bem } from '@lib/bem';
import './AlignContainer.scss';

const AlignContainer = ({ children, align = 'left' }) => {
  const { block } = bem('align_container');
  return (
    <div {...block({ align })}>
      {children}
    </div>
  );
};

export default AlignContainer;
