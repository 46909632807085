import { memo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const cn = block('main-sidebar');

const Sidebar = ({ children, className }) => (
  <aside className={cn('wrap').mix(className)}>
    {children}
  </aside>
);

export default memo(Sidebar);
