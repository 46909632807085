// constants
import { CONTENTS_LIST } from '@assets/content/text';
import { useScroll } from '@hooks/useScroll';
import { useLocation } from '@reach/router';
import { Sidebar } from '@widgets/side-bar';
// styles
import { block } from 'bem-cn';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import slugify from 'slugify';
import './style.scss';
import { MENU_ITEMS_BY_POST } from './config';

const cn = block('post-menu-sidebar');

// -----------------------------------------------------------------------------

const PostMenuSideBar = ({ className, postContentRef, data }) => {
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(0);
  const { pathname } = useLocation();
  const cyrillicToTranslit = new CyrillicToTranslit();

  const postSlug = pathname.split('/')
    .filter(Boolean)
    .pop();

  const menuItems = data || [];
  const scrollEffectEnabled = useRef(true);
  slugify.extend({ ':': '-' });
  slugify.extend({ '*': '-' });

  const headersInitialized = useRef(false);
  const postHeaders = postContentRef.current ? Array.from(postContentRef.current.querySelectorAll('h2')) : [];
  useEffect(() => {
    if (postHeaders.length > 0 && !headersInitialized.current) {
      postHeaders.forEach((headerNode, i) => {
        if (menuItems.length === postHeaders.length) {
          headerNode.setAttribute('id', headerNode.innerText ? menuItems[i].link : '');
        } else {
          headerNode.setAttribute('id', headerNode.innerText ? slugify(cyrillicToTranslit.transform(headerNode.innerText, '-')) : '');
        }
      });
      headersInitialized.current = true;
    }
  }, [postHeaders]);

  const handleScroll = () => {
    if (headersInitialized.current) {
      const marginTop = 96 + 64;
      const isScrolledToNode = (node) => {
        const { top } = node.getBoundingClientRect();
        return top <= marginTop && top > 0;
      };
      if (scrollEffectEnabled.current) {
        postHeaders.some((headerNode, index) => {
          if (isScrolledToNode(headerNode)) {
            setSelectedMenuItemIndex(index);
            return true;
          }
          return false;
        });
      } else {
        scrollEffectEnabled.current = isScrolledToNode(postHeaders[selectedMenuItemIndex]);
      }
    }
  };

  useScroll(handleScroll);

  const handleNavigate = (index) => () => {
    scrollEffectEnabled.current = false;
    setSelectedMenuItemIndex(index);
  };

  return (
    <Sidebar className={cn('wrap').mix(className)}>
      <span className={cn('title')}>{CONTENTS_LIST}</span>
      <ul className={cn('list')}>
        {postHeaders.map((item, index) => {
          if (menuItems.length === postHeaders.length) {
            return (
              <li
                key={menuItems[index].name}
                className={cn('item', {
                  active: index === selectedMenuItemIndex,
                })}
              >
                <a
                  href={`#${menuItems[index].link}`}
                  className={cn('menu', { link: true })}
                  onClick={handleNavigate(index)}
                >
                  {menuItems[index].name}
                </a>
              </li>
            );
          }
          return (
            <li
              key={cyrillicToTranslit.transform(item.innerText, '_').toLowerCase().replace(/[,—.:!?*%$#@^&()'";]/g, '_')}
              className={cn('item', {
                active: index === selectedMenuItemIndex,
              })}
            >
              <a
                href={`#${cyrillicToTranslit.transform(item.innerText, '_').toLowerCase().replace(/[,—.:!?*%$#@^&()'";]/g, '_')}`}
                className={cn('menu', { link: true })}
                onClick={handleNavigate(index)}
              >
                {item.innerText}
              </a>
            </li>
          );
        })}
      </ul>
    </Sidebar>
  );
};

PostMenuSideBar.defaultProps = {
  className: '',
};

PostMenuSideBar.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default PostMenuSideBar;
