import { useState, useEffect } from 'react';

const modifyLocation = (path) => {
  if (!path.endsWith('/')) {
    return `${path}/`;
  }
  return path;
};

export const usePostVisits = () => {
  const [postView, setPostView] = useState(null);
  useEffect(() => {
    const pathname = modifyLocation(window.location.pathname);
    fetch(`${process.env.GATSBY_API_POST_VIEW}?pageUrl=${pathname}`)
      .then((res) => res.json())
      .then((res) => setPostView(res || 0))
      .catch((err) => {
        console.log(err);
        setPostView(0);
      });
  }, []);
  return postView;
};
