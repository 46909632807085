export const posts = {
  'case-ecoclub': {
    case: {
      logo: '',
      title: 'О кейсе',
      icon: 'post-case.png',
      data: [
        {
          title: 'Цель',
          description: 'Автоматизировать начисление бонусных баллов.',
        },
        {
          title: 'Результат',
          description: 'Создали брендированное приложение G-Landia Eco с автоматизированной бонусной системой. Участники экопроекта сдают вторсырьё на переработку, отмечаются на экостанции с помощью QR-кода или приложения и получают бонусные баллы. Потом их можно тратить на покупки у компании-партнёра.',
        },
      ],
    },
    client: {
      title: 'О клиенте',
      icon: 'posts/case-ecoclub/post-client.png',
      type: 'client',
      logo: 'posts/case-ecoclub/g-landia-logo.png',
      brand: 'glandia',
      data: [
        {
          title: 'Клиент',
          description: 'Экопроект «Чистый Петербург» от фонда Джиландия',
        },
        { title: 'Деятельность', description: 'ЗОЖ-, эко- и социальные проекты.' },
        {
          title: 'Цель',
          description: 'Объединить интернет-пользователей и компании для сохранения Земли и её ресурсов.',
        },
        {
          title: 'Среди проектов за последние 5 лет',
          list:
            [
              'Чистый Мир — информационный портал',
              'Чистый Петербург — локальный экопроект',
              'G-Landia Eco — приложение для сбора вторсырья',
              'Разрабатывается социальная сеть G-Landia',
            ],
        },
      ],
    },
  },
  'choose-service-desk-for-offices': {
    simple: {
      logo: '',
      title: 'Просто о сложном',
      icon: 'help-desk.png',
      type: 'simple',
      data: [
        {
          title: 'Звучит сложно',
          description: 'Системы автоматизации сервисных заявок для управляющей компании',
        },
        {
          title: 'Объясняем просто',
          list:
            [
              'Как появились системы автоматизации обращений',
              'Чем отличаются Facility Management, CRM, FSM, Help desk и Service desk',
              'Как выбрать и успешно внедрить новый инструмент',
            ],
        },
      ],
    },
  },
  'case-coworking-automation': {
    case: {
      data: [
        {
          title: 'Цель',
          description: 'Автоматизировать типовые задачи обслуживания',
        },
        {
          title: 'Результат',
          description: 'Внедрили систему Wave Service для автоматизации рутинных задач. Теперь во всех зонах сервисного офиса есть новый канал связи резидентов и администраторов. Резиденты бронируют переговорные и сообщают о проблемах через QR-коды, а администраторы собирают обратную связь и отслеживают статистику в едином приложении.',
        },
      ],
    },
    client: {
      title: 'О клиенте',
      icon: 'posts/case-coworking-automation/post-client.png',
      type: 'client',
      logo: 'posts/case-coworking-automation/logo.png',
      brand: 'case',
      data: [
        {
          title: 'Клиент',
          description: 'Сервисный офис «<a href="https://caseclub.work/" rel="noopener noreferrer" target="_blank">CASE</a>»',
        },
        { title: 'Деятельность', description: 'Аренда, организация гибкого рабочего пространства для компаний и фрилансеров' },
        {
          title: 'Описание',
          list:
            [
              'В CASE оборудовано 220 рабочих мест для компаний и фрилансеров',
              'Площадь сервисного офиса — 1200м²',
              'Open space, трансформируемые переговорные, ресепшн, общая кухня, coffee-point, лаундж-зона, игровая комната и душевая',
              'Сервисное обслуживание на уровне бизнес-центра класса А в центре Санкт-Петербурга',
            ],
        },
      ],
    },
  },
  'case-entry-permits-teorema': {
    case: {
      data: [
        {
          title: 'Цель',
          description:
            'Упростить выдачу временных пропусков для посетителей бизнес-центров',
        },
        {
          title: 'Результат',
          description:
            'Автоматизировали выдачу временных пропусков. Теперь сотрудники и арендаторы заводят пропуска через приложение, а посетители проходят по QR-коду — и всё без очередей и журналов на пункте охраны.',
        },
      ],
    },
    client: {
      title: 'О клиенте',
      icon: 'posts/case-entry-permits-teorema/post-client.png',
      type: 'client',
      logo: 'posts/case-entry-permits-teorema/logo.png',
      brand: 'teorema',
      data: [
        {
          title: 'Клиент',
          description: 'Управляющая компания «<a href="https://www.teorema.info/" target="_blank" rel="noopener noreferrer">Теорема</a>»',
        },
        {
          title: 'Деятельность',
          description:
            'Аренда, девелопмент, эксплуатация коммерческой и загородной недвижимости',
        },
        {
          title: 'Результат 25-летней работы',
          list: [
            '10 комфортабельных бизнес-центров класса А и B+ с высокой заполняемостью',
            '440 тысяч квадратных метров офисных, торговых и складских площадей в управлении',
            'Сервисное обслуживание на уровне класса А, прекрасная инженерная оснащённость и развитая инфраструктура',
            '11 наград за реализацию проектов',
          ],
        },
      ],
    },
  },
  'case-hotel-automation': {
    case: {
      type: 'case',
      data: [
        {
          title: 'Цель',
          list: [
            'Обеспечить высокое качество сервиса с помощью технологий',
            'Повысить уровень обслуживания гостей',
            'Увеличить лояльность сотрудников',
          ],
        },
        {
          title: 'Результат',
          description:
            'Внедрили единое приложение для гостей и сотрудников. Гости заказывают дополнительные услуги с помощью QR-кода. Сотрудники благодаря удобному инструменту не теряют запросы гостей. Менеджеры работают с прозрачной аналитикой и используют данные для маркетинга.',
        },
      ],
    },
    client: {
      type: 'client',
      data: [
        {
          title: 'Клиент',
          description: '1715 DUPLEX HOTEL',
        },
        {
          title: 'Деятельность',
          list: [
            'Предоставление номеров для проживания',
            'Услуги консьержа',
            'Трансферы, аренда автомобилей, индивидуальные и групповые экскурсии, авиа- и железнодорожные билеты',
          ],
        },
        {
          title: 'Информация об отеле',
          list: [
            'Основан в 2020 году',
            '15 современных и уютных номеров',
            'Средняя заполняемость отеля в месяц — 80%',
            '11 сотрудников в штате',
          ],
        },
      ],
    },
  },
};
