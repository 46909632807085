import { navigate } from 'gatsby';
import ButtonBase from '@ui/Button';
import AlignContainer from '@ui/AlignContainer';

const Button = ({ 
  href, 
  target, 
  align, 
  ...rest
}) => {
  const clickHandle = () => {
    if (target === '_blank' || href.includes('.')) {
      window.open(href, target === '_blank' ? '_blank' : '_self');
      return;
    }
    navigate(href);
  };
  return (
    <AlignContainer align={align}>
      <ButtonBase onClick={clickHandle} {...rest} />
    </AlignContainer>
  );
};

export default Button;
