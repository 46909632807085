import {
  PostIntro, RecommendedPosts, useSitePosts,
} from '@entities/post';
import { Layout } from '@widgets/layout';
// ui
import MediaQuery from '@ui/MediaQuery';
import { PostMenuSideBar } from '@ui/SideBar';
// styles
import { block } from 'bem-cn';
import { useInView } from 'react-intersection-observer';
import { graphql } from 'gatsby';
import {
  memo, useEffect, useRef,
} from 'react';

import { usePostVisits } from '@hooks/usePostVisit';
import { useLocation } from '@reach/router';
import MarkDownViewer from '@ui/mdEditor';

import './style.scss';
import './postContentStyle.scss';

const cn = block('post-page');

// ----------------------------------------------------------------------------

const Template = ({ data }) => {
  const { hash } = useLocation();

  const { markdownRemark } = data;
  const { frontmatter, htmlAst, timeToRead } = markdownRemark;

  const postView = usePostVisits();
  const {
    slug,
    coverImageUrl,
    coverImageAlt,
    SeoDescription,
    SeoTitle,
    author,
    createdAt,
    postVisits = 0,
    category,
    title,
    avatar,
    sidemenu,
  } = frontmatter;

  const { ref: headerBgRef, inView } = useInView({
    threshold: 0.3,
    initialInView: true,
  });

  const postContentRef = useRef();

  useEffect(() => {
    if (hash && postContentRef.current) {
      const tag = postContentRef.current.querySelector(hash);
      if (tag) {
        tag.scrollIntoView();
      }
    }
  }); // don`t use dependencies array for useEffect. tag is null by reload if use array
  const recommendedPosts = useSitePosts().recommended;
  return (
    <Layout
      seo={{
        title: SeoTitle,
        description: SeoDescription,
        link: slug,
        type: 'article',
        image: coverImageUrl,
      }}
    >
      <Layout.Intro>
        <div className="grid-layout mx-auto lg:pt-[96px] lg-down:pt-[56px]">
          <PostIntro post={{ ...frontmatter, duration: timeToRead }} />
        </div>
      </Layout.Intro>
      <MediaQuery lessThan="lg">
        <div className="gradient_light relative -mt-2 sm:mx-1 md:mx-2 lg:mx-3">
          <div className="grid-layout mx-auto mb-5 pt-7 pb-5">
            <PostMenuSideBar postContentRef={postContentRef} data={sidemenu || null} />
          </div>
        </div>
      </MediaQuery>
      <article className="grid-layout mx-auto sm:px-3 lg:flex">
        <div className="lg:col-offset-start-1 lg:col-8">
          <div className={cn('post', { container: true })}>
            <div
              className={cn('post', { content: true })
                .mix(
                  cn('content', { wrap: true }),
                  'lg:col-8',
                )}
              ref={postContentRef}
            >
              <MarkDownViewer>
                {htmlAst}
              </MarkDownViewer>
            </div>
            <MediaQuery greaterThan="md">
              <PostMenuSideBar postContentRef={postContentRef} data={sidemenu || null} />
            </MediaQuery>
          </div>
        </div>
      </article>
      <RecommendedPosts
        className="pt-12 lg-down:pt-7"
        posts={recommendedPosts}
        listClassName="lg:mt-6 lg:mb-[50px] lg-down:mt-5 lg-down:mb-6"
      />
    </Layout>
  );
};

export default memo(Template);

export const pageQuery = graphql`
  query($id: String!) {
      markdownRemark(id: { eq: $id }) {
        htmlAst
        timeToRead
          frontmatter {
              author
              category
              coverImageUrl
              coverImageAlt
              createdAt
              slug
              title
              avatar
              SeoTitle
              SeoDescription
              sidemenu {
                name
                link
              }
              }
          }
      }
`;
