// ui
// import { Link as GatsbyLink } from 'gatsby';
// styles
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { memo } from 'react';
import StaticIcon from '@ui/StaticIcon';
import './style.scss';

const cn = block('social-sidebar');

// -------------------------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'facebook',
    icon: <StaticIcon name="facebook" />,
    link: 'https://facebook.com',
  },
  {
    name: 'twitter',
    icon: <StaticIcon name="twitter" />,
    link: 'https://twitter.com',
  },
  {
    name: 'linkedin',
    icon: <StaticIcon name="linkedin" />,
    link: 'https://linkedin.com',
  },
];

// -------------------------------------------------------------------------------------

const SocialSideBar = ({ className }) => (
  <aside className={cn('wrap')
    .mix(className)}
  >
    <ul className={cn('list')}>
      {SOCIALS.map((social) => (
        <li className={cn('icon', { wrap: true })} key={social.name}>
          {/* <GatsbyLink to={social.link} className={cn('icon', { link: true })}>
						{social.icon}
					</GatsbyLink> */}
        </li>
      ))}
    </ul>
  </aside>
);

SocialSideBar.defaultProps = {
  className: '',
};

SocialSideBar.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(SocialSideBar);
