import { memo } from 'react';
import { block } from 'bem-cn';
import { Link } from 'gatsby';

import Image from '@ui/Image';
import Typography, { getTypographyClass } from '@ui/Typography';
import UnderlineWords from '@ui/UnderlineWords';

import './style.scss';
import { useMediaQuery } from '@hooks/useMediaQuery';

const cn = block('productBanner');

const ALL_NEEDS = [
  'Service requests',
  'Maintenance',
  'Checklists',
  'Asset inventory',
  'Bookings',
  'Analytics and more',
];

const ProductBanner = () => {
  const isDesktop = useMediaQuery('(min-width: 688px)');
  return (
    <div className={cn('wrap')}>
      <Typography variant="header3" className="mb-4">
        Easy-to-use app for facility managers
      </Typography>
      <span className={cn('description')}>
        Future-ready way to digitalise your service requests, coordinate your teams
        and continuously improve.
      </span>
      <div className={cn('content', { wrap: true })}>
        <div className={cn('content', { link: 'wrap' })}>
          <Link
            to="/"
            className={getTypographyClass({
              variant: 'button1',
              className: cn('content', { link: true }).toString(),
            })}
          >
            Learn more
          </Link>
        </div>
        <Image src="/images/functional-icons.png" />
      </div>
      <div className={cn('content', { wrap: true })}>
        {isDesktop && (
          <span className={cn('text', { title: true })}>
            All you need for high-quality service:
          </span>
        )}
        <div>
          {ALL_NEEDS.map((item) => (
            <span className={cn('text', { item: true })}>
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductBanner);
