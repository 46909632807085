import { useLocation } from '@reach/router';
import Image from '@ui/Image';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styled from 'windstitch';
import { memo, useMemo } from 'react';
import { posts } from './constants';

const TYPES = {
  case: {
    title: 'Case',
    icon: 'bg-orange100',
  },
  client: {
    title: 'Client',
    icon: 'bg-cyan100',
  },
  simple: {
    title: 'Just about complex',
    icon: 'bg-teal100',
  },
};

const ListItemDot = styled.div('mt-[13px] h-[4px] min-w-[4px] rounded-full bg-primary400');

const TextBlock = ({ data }) => {
  const {
    title,
    description,
    list,
  } = data;

  if (title && title === 'client') {
    return (
      <div className="mt-3 ml-[-32px] bg-tertiary200 py-[2px] pr-1.5 pl-4 w-fit">
        <Typography className="!text-xs !font-medium !mb-0">
          {description}
        </Typography>
      </div>
    );
  }

  return (
    <div className="mt-3">
      {
        title && (
          <Typography
            id="case-title"
            variant="body2"
            weight="medium"
            color="secondary200"
          >
            {title}
          </Typography>
        )
      }
      {
        description && (
          <Typography id="case-description" className="!mt-1 !mb-0">
            {description}
          </Typography>
        )
      }
      {
        list && (
          <ul id="case-list" className="!mt-1 !mb-0">
            {
              list.map((x) => (
                <li
                  id="case-list-item"
                  className="!pl-0"
                  key={x}
                >
                  <ListItemDot />
                  <Typography>
                    {x}
                  </Typography>
                </li>
              ))
            }
          </ul>
        )
      }
    </div>
  );
};

const Container = styled.div(clsx(
  'case-card',
  'relative',
  'rounded-[16px] border-solid border-[1px] border-tertiary300',
  'lg-down:rounded-t-xl',
  'px-4 pt-6 pb-3 my-6',
));

const CardIcon = ({ type }) => {
  const bgColor = TYPES[type].icon;

  return (
    <div className={clsx('py-1 px-1.5 w-fit rounded-lg absolute top-[-20px]', bgColor)}>
      <Image src={`/images/${type}.png`} alt={`${type} icon`} />
    </div>
  );
};

const DescriptionContainer = styled.div(clsx(
  'space-y-3',
));

const CardDescription = ({ caseData, brand }) => {
  return (
    <DescriptionContainer>
      {
        caseData && (
          caseData.map((x) => (
            <TextBlock data={x} key={x.title} brand={brand} />
          ))
        )
      }
    </DescriptionContainer>
  );
};

const getContent = (pathname, type) => {
  const path = Object.keys(posts).find((slug) => pathname.includes(slug));
  if (!path) {
    return null;
  }
  const content = posts[path][type];
  return content;
};

const CaseCard = ({ type, ...rest }) => {
  const dataString = rest && rest.children && rest.children[0];
  let carddata = [];
  try {
    carddata = JSON.parse(dataString);
  } catch (err) {
    console.log(`Parsing error. Data: ${dataString} Error: ${err}`);
  }

  const { pathname } = useLocation();

  const content = useMemo(() => getContent(pathname, type), [type, pathname]);

  if (!carddata.length && !content) {
    return null;
  }

  const data = carddata.length ? carddata : content.data;

  return data.length > 0 && (
    <Container>
      <CardIcon type={type} />
      <Typography className={clsx('mb-3 !mt-0 !font-medium')} id="title">
        {TYPES[type].title}
      </Typography>
      <div className="border-b-solid border-b-[1px] border-b-tertiary300" />
      <CardDescription caseData={data} />
    </Container>
  );
};

CaseCard.defaultProps = {
  type: 'case',
};

CaseCard.propTypes = {
  type: PropTypes.oneOf(['case', 'client', 'simple']),
};

export default memo(CaseCard);
