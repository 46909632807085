import { bem } from '@lib/bem';
import './style.scss';
import clsx from 'clsx';
import MediaQuery from '../MediaQuery';
import Slider from '../Slider';

const {
  block,
  element,
} = bem('images-row');

const ImagesRow = (props) => {
  const {
    children,
    className,
  } = props;
  const count = children.length;
  return (
    <div {...block()}>
      <MediaQuery greaterThan="md">
        <div
          className={clsx('grid gap-5', {
            'grid-cols-2': count === 2,
            'grid-cols-3': count === 3,
            'grid-cols-4': count === 4,
          }, className)}
        >
          {
            children.map((item, index) => (
              <img
                className="max-w-full first:ml-auto"
                src={item.props.src}
                alt={item.props.alt}
                key={item.key}
              />
            ))
          }
        </div>
      </MediaQuery>
      <MediaQuery at="sm">
        <Slider className={clsx(element('slider').className, className)}>
          {children.map((item) => (
            <img
              className="!max-w-[222px]"
              src={item.props.src}
              alt={item.props.alt}
              key={item.key}
            />
          ))}
        </Slider>
      </MediaQuery>
      <MediaQuery at="md">
        <Slider settings={{ slidesToShow: 2 }} className={clsx(element('slider').className, className)}>
          {children.map((item) => (
            <img
              {...element('image')}
              src={item.props.src}
              alt={item.props.alt}
              key={item.key}
            />
          ))}
        </Slider>
      </MediaQuery>
    </div>
  );
};

export default ImagesRow;
