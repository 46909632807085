import block from 'bem-cn';

import './style.scss';

const cn = block('accent-text');

const AccentText = ({ children }) => (
  <span className={cn()}>
    {children}
  </span>
);

export default AccentText;
